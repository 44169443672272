<template>
  <b-container class="jasperfieldsmanagement">
    <b-row>
      <b-col cols="12" md="12" lg="10" offset-lg="1">
        <h2>Jasper Field Mapping</h2>
        <b-button variant="primary" class="mx-1 my-1" @click="showAddField"
          >Create New Field Mapping</b-button
        >
        <b-button variant="primary" class="mx-1 my-1" @click="showImportCSV"
          >Import Field Mapping from CSV</b-button
        >
        <b-button variant="primary" class="mx-1 my-1" @click="refreshFields"
          >Refresh</b-button
        >
        <b-table
          striped
          small
          hover
          :fields="jasperFieldFields"
          :items="jasperFieldsList"
          :sort-by.sync="fieldsSortBy"
          :tbody-tr-class="rowClass"
        >
          <template #cell(is_optional)="data">
            <b-icon
              v-if="!data.item.is_optional"
              icon="check-square-fill"
              variant="success"
            ></b-icon>
            <b-icon v-else icon="x-square-fill" variant="secondary"></b-icon>
          </template>
          <template #cell(include_in_ingest)="data">
            <b-icon
              v-if="data.item.include_in_ingest"
              icon="check-square-fill"
              variant="success"
            ></b-icon>
            <b-icon v-else icon="x-square-fill" variant="secondary"></b-icon>
          </template>
          <template #cell(include_in_update)="data">
            <b-icon
              v-if="data.item.include_in_update"
              icon="check-square-fill"
              variant="success"
            ></b-icon>
            <b-icon v-else icon="x-square-fill" variant="secondary"></b-icon>
          </template>
          <template #cell(allow_empty)="data">
            <b-icon
              v-if="data.item.allow_empty"
              icon="check-square-fill"
              variant="success"
            ></b-icon>
            <b-icon v-else icon="x-square-fill" variant="secondary"></b-icon>
          </template>
          <template #cell(actions)="data">
            <b-button
              class="mx-2"
              size="sm"
              @click="showDeleteField(data.item.id)"
              variant="outline-danger"
              v-if="!data.item.is_locked"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
            <b-icon v-else icon="shield-lock-fill"></b-icon>
          </template>
        </b-table>
        <h4>DateTime Formats</h4>
        <p><i>One line per format</i></p>
        <b-form-textarea
          id="datetimeformats-textarea"
          v-model="dateTimeFormatsText"
          placeholder="DateTime formats, one per line"
          rows="8"
        ></b-form-textarea>
        <b-button
          variant="warning" 
          class="mx-1 my-1"
          @click="getDateTimeFormats"
          >Reload formats</b-button>
        <b-button
          variant="primary"
          class="mx-1 my-1"
          @click="submitDateFormats"
        >Submit DateTime formats</b-button>
        <p>See:<i><a href="https://docs.python.org/3.10/library/datetime.html#strftime-and-strptime-format-codes">DateTime Format Codes</a></i></p>
      </b-col>
    </b-row>
    <b-modal
      ref="newFieldModal"
      title="Create new field mapping"
      hide-header-close
      @ok="addFieldMapping"
      :ok-disabled="!newFieldFormValid"
    >
      <b-form-group
        label="Source name:"
        label-for="newFieldSourceName"
        :state="newFieldSourceNameState"
        :invalid-feedback="defaultFeedback"
        description="Header column name in the Jasper export."
      >
        <b-form-input
          id="newFieldSourceName"
          v-model="newFieldSourceName"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Shotgrid name:"
        label-for="newFieldShotgridName"
        :state="newFieldShotgridNameState"
        :invalid-feedback="defaultFeedback"
        description="Field code on Shotgrid. This is usually 'sg_...'. For example: 'sg_wt'."
      >
        <b-form-input
          id="newFieldShotgridName"
          v-model="newFieldShotgridName"
        ></b-form-input>
      </b-form-group>
      <b-form-checkbox
        id="newFieldIsRequired"
        v-model="newFieldIsRequired"
        switch
        >Required. When ticked, the field must exist in the Jasper
        export.</b-form-checkbox
      >
      <b-form-checkbox
        id="newFieldIncludeInIngest"
        v-model="newFieldIncludeInIngest"
        switch
        >Include in ingest. When ticked, the field mapping is used during
        ingest.</b-form-checkbox
      >
      <b-form-checkbox
        id="newFieldIncludeInUpdate"
        v-model="newFieldIncludeInUpdate"
        switch
        >Include in update. When ticked, the field mapping is used during
        (automated) updates.</b-form-checkbox
      >
      <b-form-checkbox
        id="newFieldAllowEmpty"
        v-model="newFieldAllowEmpty"
        switch
        >Allow empty values. When ticked, the values for this field may be empty
        in the report.</b-form-checkbox
      >
      <b-form-checkbox
        id="newFieldAllowSGUndefined"
        v-model="newFieldAllowSGUndefined"
        switch
        >Allow Shotgrid field to be undefined. This should be used for
        metafields only. [Careful!]</b-form-checkbox
      >
      <ul>
        <li v-for="line in newRuleValidationMessages" :key="line">
          {{ line }}
        </li>
      </ul>
    </b-modal>
    <b-modal
      ref="importRulesCSVModal"
      title="Import field mappings"
      hide-header-close
      ok-only
      ok-title="Close"
      ok-variant="secondary"
    >
      <b-file
        id="csvFileDrop"
        v-model="importFieldsCSVFile"
        :state="importRulesCSVFormValid"
        class="big-file-input"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      ></b-file>
      <b-button
        class="mx-1 my-1"
        @click="submitCSVFile"
        variant="primary"
        :disabled="!importRulesCSVFormValid"
        >Submit file</b-button
      >
    </b-modal>
    <b-modal
      ref="csvSubmitModal"
      title="Importing CSV"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <b-list-group>
        <b-list-group-item
          v-for="stage in submitInfo.stages"
          :key="stage.index"
          class="flex-column justify-content-between"
        >
          <b-icon
            v-if="stage.state == STAGE_STATES.WAITING"
            icon="arrow-clockwise"
          ></b-icon>
          <b-icon
            v-if="stage.state == STAGE_STATES.IN_PROGRESS"
            icon="arrow-clockwise"
            variant="primary"
            animation="spin"
          ></b-icon>
          <b-icon
            v-if="stage.state == STAGE_STATES.FINISHED"
            icon="check-circle"
            variant="success"
          ></b-icon>
          <b-icon
            v-if="stage.state == STAGE_STATES.ERROR"
            icon="x-circle"
            variant="danger"
          ></b-icon>
          {{ stage.display_text }}
          <b-progress
            v-if="stage.progressTotal"
            :value="stage.progress"
            :max="stage.progressTotal"
            show-progress
          >
            <b-progress-bar :value="stage.progress">
              <span>
                <strong>{{ stage.progress.toFixed(1) }} %</strong>
              </span>
            </b-progress-bar>
          </b-progress>
        </b-list-group-item>
      </b-list-group>
      <div v-if="submitInfo.finished">
        <!-- <h3>Result</h3> -->
        <p>{{ submitInfo.resultText }}</p>
        <ul>
          <li v-for="line in submitInfo.resultLines" :key="line">{{ line }}</li>
        </ul>
      </div>
      <b-button block @click="closeSubmitModal" :disabled="!submitInfo.finished"
        >Close</b-button
      >
    </b-modal>
    <b-modal
      ref="deleteFieldModal"
      title="Disable field?"
      hide-header-close
      @ok="disableRule"
    >
      <template #modal-ok>Confirm</template>
      <label>Field ID:</label>
      <b-form-input v-model="deleteFieldId" disabled></b-form-input>
    </b-modal>
  </b-container>
</template>
<script>
import api from "@/backendapi";
import { defaultToastBody, defaultToastConfig } from "@/util";

const _JASPER_DATETIME_FORMAT_CONFIG_KEY = "jasper_datetime_format";

const _STAGE_STATES = {
  WAITING: 10,
  IN_PROGRESS: 20,
  FINISHED: 30,
  ERROR: -100,
};

const _resetSubmitInfo = () => {
  return {
    finished: false,
    resultText: "",
    resultLines: [],
    stages: {
      UPLOAD: {
        index: 0,
        state: _STAGE_STATES.WAITING,
        display_text: "Uploading file",
        progress: 0,
        progressTotal: 100,
      },
      WAIT_RESP: {
        index: 1,
        state: _STAGE_STATES.WAITING,
        display_text: "Waiting for server processing",
      },
    },
  };
};

export default {
  name: "JasperFieldManagement",
  data: function () {
    return {
      STAGE_STATES: _STAGE_STATES,
      newFieldSourceName: "",
      newFieldShotgridName: "sg_",
      newFieldIsRequired: false,
      newFieldIncludeInIngest: true,
      newFieldIncludeInUpdate: true,
      newFieldAllowEmpty: true,
      newFieldAllowSGUndefined: false,
      newRuleValidationMessages: [],
      newRuleValidationLoading: false,
      importFieldsCSVFile: null,
      submitInfo: _resetSubmitInfo(),
      deleteFieldId: null,
      jasperFieldFields: [
        "id",
        { key: "source_name", sortable: true },
        { key: "shotgrid_name", sortable: true },
        { key: "is_optional", label: "Required" },
        { key: "include_in_ingest" },
        { key: "include_in_update" },
        { key: "allow_empty" },
        { key: "actions" },
      ],
      fieldsSortBy: "source_name",
      dateTimeFormatsText: ""
    };
  },
  computed: {
    jasperFieldsList() {
      return this.$store.state.jasperFieldsList;
    },
    newFieldSourceNameState() {
      return this.newFieldSourceName.length > 0;
    },
    newFieldShotgridNameState() {
      return this.newFieldShotgridName.length > 0;
    },
    defaultFeedback() {
      return "Required";
    },
    newFieldFormValid: function () {
      return this.newFieldSourceNameState && this.newFieldShotgridNameState;
    },
    fileIsValid() {
      if (this.importFieldsCSVFile == null) {
        return null;
      }
      return this._validateFile(this.importFieldsCSVFile);
    },
    importRulesCSVFormValid: function () {
      return this.fileIsValid;
    },
  },
  methods: {
    rowClass: function (ruleObj, type) {
      if (!ruleObj || type !== "row") {
        return;
      } // Skip
      //if (ruleObj.is_locked) return "table-secondary";
    },
    showAddField: function () {
      this.newFieldSourceName = "";
      this.newFieldShotgridName = "sg_";
      this.newFieldAllowSGUndefined = false;
      this.$refs["newFieldModal"].show();
    },
    addFieldMapping: function () {
      let payload = {
        source_name: this.newFieldSourceName,
        shotgrid_name: this.newFieldShotgridName,
        is_optional: !this.newFieldIsRequired,
        include_in_ingest: this.newFieldIncludeInIngest,
        include_in_update: this.newFieldIncludeInUpdate,
        allow_empty: this.newFieldAllowEmpty,
        allow_sg_undefined: this.newFieldAllowSGUndefined,
      };
      this.$store
        .dispatch("createJasperField", payload)
        .then((res) => {
          let toastConfig = defaultToastConfig();
          toastConfig.title = "New field mapping created.";
          toastConfig.variant = "success";
          this.$bvToast.toast(`Created field ID: ${res.id}`, toastConfig);
          this.refreshFields();
        })
        .catch((err) => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    showImportCSV: function () {
      this.importRulesCSV = null;
      this.$refs["importRulesCSVModal"].show();
    },
    _validateFile: function (_file) {
      if (!_file) {
        return false;
      }
      let fparts = _file.name.split(".");
      if (fparts.length <= 1 || fparts[fparts.length - 1] !== "csv") {
        return false;
      }
      return true;
    },
    _checkFiles() {
      if (!this.importRulesCSVFormValid) {
        let toastConfig = defaultToastConfig();
        toastConfig.title = "No file set!";
        toastConfig.variant = "warning";
        this.$bvToast.toast(
          `Please browse or drop a file on the file box.`,
          toastConfig
        );
        return false;
      }
      return true;
    },
    async submitCSVFile() {
      // Preflight check
      if (!this._checkFiles()) {
        return;
      }
      // Setup before showing modal
      this.submitInfo = _resetSubmitInfo();
      this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.IN_PROGRESS;
      this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.IN_PROGRESS;
      this.$store.commit("setLeaveGuard", true);
      this.$refs["csvSubmitModal"].show();
      // Step 1: Upload file
      let fileData = new FormData();
      // let file = this.files[0];
      let _file = this.importFieldsCSVFile;
      // TODO: Error handling missing file here?
      fileData.append("csv", _file, _file.name);
      let requestConfig = {
        onUploadProgress: (progEvt) => {
          this.submitInfo.stages.UPLOAD.progress =
            (progEvt.loaded / progEvt.total) * 100;
          if (this.submitInfo.stages.UPLOAD.progress >= 99.999999) {
            this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.FINISHED;
          }
        },
      };
      try {
        let res = await api.postSubmitJasperFieldCSV(
          localStorage.token,
          fileData,
          requestConfig
        );
        this._setSubmitResult(res);
        this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.FINISHED;
        this.refreshFields();
      } catch (err) {
        console.log(err);
        this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.ERROR;
        this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.ERROR;
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      } finally {
        this.submitInfo.finished = true;
        this.$store.commit("setLeaveGuard", false);
      }
    },
    _setSubmitResult(respData) {
      console.log(
        "TODO: Implement submit result show? I think a refresh is enough."
      );
      console.log(respData);
    },
    closeSubmitModal() {
      this.$refs["csvSubmitModal"].hide();
    },
    refreshFields: function () {
      this.$store
        // TODO: Set loading state to true
        .dispatch("getJasperFields")
        .then(() => {
          let toastConfig = defaultToastConfig();
          toastConfig.title = "Data loaded";
          toastConfig.variant = "success";
          toastConfig.autoHideDelay = 800; // Quicker message
          this.$bvToast.toast(
            `Jasper field definitions loaded from server successfully.`,
            toastConfig
          );
        })
        .catch((err) => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    showDeleteField: function (ruleId) {
      this.deleteFieldId = ruleId;
      this.$refs["deleteFieldModal"].show();
    },
    disableRule: function () {
      this.$store
        .dispatch("deleteJasperField", this.deleteFieldId)
        .then(() => {
          this.refreshFields();
        })
        .catch((err) => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    getDateTimeFormats: async function() {
      let token = localStorage.token;
      try{
        let dateTimeFormats = await api.getConfigKeyValues(token, _JASPER_DATETIME_FORMAT_CONFIG_KEY);
        console.log(dateTimeFormats)
        dateTimeFormats.values.sort()
        this.dateTimeFormatsText = dateTimeFormats.values.join('\n');
        let toastConfig = defaultToastConfig();
        toastConfig.title = "Data loaded";
        toastConfig.variant = "success";
        toastConfig.autoHideDelay = 800; // Quicker message
        this.$bvToast.toast(
          `DateTime formats loaded from server.`,
          toastConfig
        );
      } catch (err) {
        console.log(err);
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      }
    },
    submitDateFormats: async function(){
      let token = localStorage.token;
      let values = this.dateTimeFormatsText.split("\n");
      try {
        await api.postConfigKeyValues(token, _JASPER_DATETIME_FORMAT_CONFIG_KEY, values);
        let toastConfig = defaultToastConfig();
        toastConfig.title = "Data submitted";
        toastConfig.variant = "success";
        this.$bvToast.toast(
          `DateTime formats set.`,
          toastConfig
        );
        await this.getDateTimeFormats()
      } catch (err) {
        console.log(err);
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      }
    }
  },
  components: {},
  created() {},
  mounted() {
    if (this.$store.state.jasperFieldsList.length === 0) {
      this.refreshFields();
    }
    if (this.dateTimeFormatsText.length === 0) {
      this.getDateTimeFormats();
    }
  },
};
</script>